<template>
  <div>
    <reservation class="center" style="max-width: 800px; width: 94%" />

    <div style="padding: 10px">
      <slideshow style="max-width: 800px" class="center" :slides="slides"></slideshow>
    </div>

    

    <div class="grid-container">
      <div class="main-content about-section">

        <p>
We offer spacious suites for large families or groups, each with 3 or 4 queen-size beds.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Reservation from "@/components/Reservation.vue";
import Slideshow from '@/components/Slideshow.vue';

export default {
  name: "Rooms",
  components: {
    Reservation,
    Slideshow,
  },
  data() {
    return {
      slides: [
        { image: require("@/assets/room.jpg"), description: "Two queen beds" },
        { image: require("@/assets/room_c.jpg"), description: "Three queen beds" },
        { image: require("@/assets/single.jpg"), description: "Room viewed facing door" },
        { image: require("@/assets/single_0.jpg"), description: "Room viewed facing bathroom" },
        { image: require("@/assets/air_conditioning_in_room_c.jpg"), description: "Room viewed facing air conditioning" },
        { image: require("@/assets/fridge_microwave.jpg"), description: "Fridge & microwave" },
        // Add more slides here
      ]
    }
  },
};
</script>